window.Swal = require('sweetalert2');
window.Toastify = require('toastify-js');
window.htmlToImage = require('html-to-image');

$(document).on('click', '.dropdown .dropdown-menu .dropdown-item-p', setDropDownValue);
function setDropDownValue(){
    $(this).parent().parent().attr('value',$(this).attr('value'));
    if($(this).parent().parent().has('.dropdown-btn-input').length>0)
    {
        $(this).parent().parent().find('.dropdown-btn-input').val($(this).text());
    }else{
        $(this).parent().parent().find('.dropdown-btn-text').text($(this).text());
    }
}